import type { MultilinkStoryblok } from '@/types/component-types-sb'

/**
 * Storyblok has an odd behavior where the Multilink component, which is
 * imported by the CLI and generated by `storyblok-generate-ts`, only has a
 * limited number of attributes which don't reflect the actual attributes of
 * the objects delivered by the Storyblok API. Because the Storyblok API
 * has significantly more attributes than what's listed in the type
 * declaration, we need to allow full freedom of the type by adding this hacky
 * intersection with an `any`` object in the type specification.
 */
export const computeUrl = (
  link: (MultilinkStoryblok & { [k: string]: any }) | undefined,
): string => {
  const { query } = useRoute()

  /**
   * While the page is open within the Storyblok UI in the visual editor, it
   * should be impossible to move from page to page as this would create a
   * state in which the user is looking at different page from what they are
   * editing. Therefore, we blok all links when the page is open within the
   * visual editor.
   */
  const inEditor = query._storyblok ? true : false

  if (inEditor) {
    return ''
  }

  if (link === undefined) {
    console.warn('An undefined link has been passed')
    return ''
  }

  switch (link?.linktype) {
    case 'story':
      if (!link.story) {
        return ''
      }

      const fullSlug = link.story.full_slug

      // we need to test if the story object exists because it won't be resolved when the bridge is used on site-config
      if (fullSlug.endsWith('/') && link.anchor) {
        return '/' + fullSlug.replace(/\/$/, '#') + link.anchor
      } else if (link.anchor) {
        return '/' + fullSlug + '#' + link.anchor
      }

      // The replace function makes sure the links don't end in a '/'
      return '/' + fullSlug.replace(/\/$/, '')
    case 'url':
    case 'asset':
      return link.url
    case 'email':
      return 'mailto:' + link.email
    default:
      if (link !== undefined) {
        console.warn(
          `A link with the id ${link?.id} doesn't have a defined location`,
        )
      }

      return ''
  }
}
